import { FC, useRef, TouchEvent, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import barVideo from '../assets/bar_video.mp4'
import { useGlobalContext } from '../GlobalContext'

// Keyframes for letter animation
const fadeIn = keyframes`
   0% {
      opacity: 0;
      transform: translateY(20px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
`

// Animasyonlar
const growCircle = keyframes`
  0% {
    clip-path: circle(0% at 50% 50%); /* Tam ekranın ortasından başla */
  }
  100% {
    clip-path: circle(150% at 50% 50%); /* Çemberin çapı ekranın köşelerine ulaşacak kadar büyür */
  }
`

const shrinkCircle = keyframes`
  0% {
    clip-path: circle(150% at 50% 50%);
  }
  100% {
    clip-path: circle(0% at 50% 50%);
  }
`

// Styled components
const Section = styled.section`
   color: white;
   position: relative;
   overflow: hidden;
   height: 100vh;
   touch-action: pan-y pinch-zoom; /* Allow vertical scrolling and pinch zoom, but prevent horizontal */
`

const VideoWrapper = styled.div`
   width: 100%;
   height: 100%;
   position: relative;

   video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      z-index: 0;
      transition: opacity 0.5s ease-in-out;
   }

   .hidden-video {
      opacity: 0;
      pointer-events: none;
      z-index: -1;
   }

   .visible-video {
      opacity: 1;
      z-index: 0;
   }
`

const GradientOverlay = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
   );
   z-index: 1;
`

const ContentWrapper = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 2;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
`

const Bobo = styled.div`
   font-family: 'Sitka Small', serif;
   font-size: 5rem;
   line-height: 1;
   position: relative;
   margin-bottom: 10rem;
   display: flex;
   right: 2rem;
   user-select: none;
   span {
      display: inline-block;
      opacity: 0;
      animation: ${fadeIn} 0.5s forwards;
   }

   span:nth-child(1) {
      animation-delay: 0s;
   }
   span:nth-child(2) {
      animation-delay: 1.5s;
   }
   span:nth-child(3) {
      animation-delay: 0.2s;
   }
   span:nth-child(4) {
      animation-delay: 1.5s;
   }

   @media (min-width: 768px) {
      font-size: 7rem;
   }
`

const Franco = styled.div`
   font-family: 'Sitka Small', serif;
   font-size: 5rem;
   position: absolute;
   top: 3.7rem; /* Adjusts the vertical position below 'bobo' */
   left: 3.7rem; /* Adjusts the horizontal offset to be slightly to the right */
   display: flex;

   @media (min-width: 768px) {
      padding-top: 2.5rem; /* md:pt-10 */
      padding-bottom: 3.25rem; /* md:pb-13 */
   }

   span {
      display: inline-block;
      opacity: 0;
      animation: ${fadeIn} 0.5s forwards;
   }

   span:nth-child(1) {
      animation-delay: 0.4s;
   }
   span:nth-child(2) {
      animation-delay: 0.5s;
   }
   span:nth-child(3) {
      animation-delay: 0.6s;
   }
   span:nth-child(4) {
      animation-delay: 0.7s;
   }
   span:nth-child(5) {
      animation-delay: 0.8s;
   }
   span:nth-child(6) {
      animation-delay: 1.5s;
   }

   @media (min-width: 768px) {
      font-size: 7rem;
   }
`

const CircularProgressWrapper = styled.div<{
   isGrowing: boolean
   isShrinking: boolean
}>`
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background: #eee3d1;
   animation: ${({ isGrowing, isShrinking }) =>
         isGrowing ? growCircle : isShrinking ? shrinkCircle : 'none'}
      2s ease-out forwards;
   display: ${({ isGrowing, isShrinking }) =>
      isGrowing || isShrinking ? 'flex' : 'none'};
   justify-content: center;
   align-items: center;
   z-index: 3;
`

interface HeroSectionProps {
   onViewChange: () => void
}

const HeroSection: FC<HeroSectionProps> = ({ onViewChange }) => {
   const { view } = useGlobalContext()
   const touchStartX = useRef<number | null>(null)
   const touchEndX = useRef<number | null>(null)
   const touchStartY = useRef<number | null>(null)
   const touchEndY = useRef<number | null>(null)
   const mainVideoRef = useRef<HTMLVideoElement>(null)
   const barVideoRef = useRef<HTMLVideoElement>(null)
   const minSwipeDistance = 150

   // Initialize and play both videos immediately
   useEffect(() => {
      const initializeVideos = async () => {
         try {
            if (barVideoRef.current) {
               barVideoRef.current.load()
               // Start playing bar video if we're in bar view
               if (view === 'bar') {
                  const playPromise = barVideoRef.current.play()
                  if (playPromise !== undefined) {
                     await playPromise
                  }
               }
            }
         } catch (error) {
            console.error('Video initialization error:', error)
         }
      }

      initializeVideos()
   }, [view]) // Run only once on mount

   // Handle view changes
   useEffect(() => {
      const updateVideoPlayback = async () => {
         try {
            if (view === 'store') {
               if (mainVideoRef.current && mainVideoRef.current.paused) {
                  const playPromise = mainVideoRef.current.play()
                  if (playPromise !== undefined) {
                     await playPromise
                  }
               }
            } else if (view === 'bar') {
               if (barVideoRef.current && barVideoRef.current.paused) {
                  const playPromise = barVideoRef.current.play()
                  if (playPromise !== undefined) {
                     await playPromise
                  }
               }
            }
         } catch (error) {
            console.error('Video playback error:', error)
         }
      }

      updateVideoPlayback()
   }, [view]) // Run when view changes

   const handleTouchStart = (e: TouchEvent) => {
      touchStartX.current = e.touches[0].clientX
      touchStartY.current = e.touches[0].clientY
      touchEndX.current = null
      touchEndY.current = null
   }

   const handleTouchMove = (e: TouchEvent) => {
      touchEndX.current = e.touches[0].clientX
      touchEndY.current = e.touches[0].clientY
   }

   const handleTouchEnd = () => {
      if (
         !touchStartX.current ||
         !touchEndX.current ||
         !touchStartY.current ||
         !touchEndY.current
      )
         return

      const swipeDistanceX = touchEndX.current - touchStartX.current
      const swipeDistanceY = Math.abs(touchEndY.current - touchStartY.current)

      // Yatay hareket dikey hareketten en az 2 kat fazlaysa ve minimum mesafeyi geçiyorsa
      if (
         Math.abs(swipeDistanceX) > swipeDistanceY * 2 &&
         Math.abs(swipeDistanceX) > minSwipeDistance
      ) {
         if (
            (view === 'store' && swipeDistanceX > 0) ||
            (view === 'bar' && swipeDistanceX < 0)
         ) {
            onViewChange()
         }
      }

      touchStartX.current = null
      touchEndX.current = null
      touchStartY.current = null
      touchEndY.current = null
   }

   return (
      <Section>
         <GradientOverlay />
         <VideoWrapper>
            <video
               ref={barVideoRef}
               autoPlay
               loop
               muted
               playsInline
               preload="auto"
               className={view === 'bar' ? 'visible-video' : 'hidden-video'}>
               <source src={barVideo} type="video/mp4" />
            </video>
         </VideoWrapper>

         <ContentWrapper>
            <Bobo>
               <span>b</span>
               <span>o</span>
               <span>b</span>
               <span>o</span>
               <Franco>
                  <span>f</span>
                  <span>r</span>
                  <span>a</span>
                  <span>n</span>
                  <span>c</span>
                  <span>o</span>
               </Franco>
            </Bobo>
         </ContentWrapper>
      </Section>
   )
}

export default HeroSection
