import React, {
   createContext,
   useContext,
   useState,
   FC,
   ReactNode,
} from 'react'
import { translations, TranslationKeys } from './translations'

type ViewState = 'store' | 'bar'
type Language = 'tr' | 'en'

interface GlobalContextType {
   view: ViewState
   setView: (view: ViewState) => void
   language: Language
   setLanguage: (lang: Language) => void
   t: (key: keyof TranslationKeys) => string
}

interface GlobalProviderProps {
   children: ReactNode
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined)

export const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
   const [view, setView] = useState<ViewState>('bar')
   const [language, setLanguage] = useState<Language>('tr')

   const t = (key: keyof TranslationKeys): string => {
      return translations[language][key]
   }

   return (
      <GlobalContext.Provider
         value={{ view, setView, language, setLanguage, t }}>
         {children}
      </GlobalContext.Provider>
   )
}

export const useGlobalContext = () => {
   const context = useContext(GlobalContext)
   if (!context) {
      throw new Error('useGlobalContext must be used within a GlobalProvider')
   }
   return context
}
