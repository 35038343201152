import storeImage from '../assets/homepage/store.png'
import barImage from '../assets/homepage/bar.png'
import eventsImage from '../assets/homepage/events.png'
import { TranslationKeys } from '../translations'

export type MenuItem = {
   nameKey: string
   descriptionKey: string
   price: number | string
   maxPrice?: number | string
   flavorKey?: string
}

export type MenuSubcategory = {
   nameKey: keyof TranslationKeys
   items: MenuItem[]
}

export type MenuCategory = {
   nameKey: keyof TranslationKeys
   subcategories?: MenuSubcategory[]
   items?: MenuItem[]
}

export type MenuData = {
   [key: string]: MenuCategory
}

export type EventData = {
   nameKey: keyof TranslationKeys
   descriptionKey: keyof TranslationKeys
   img: string
}

export const eventsData: EventData[] = [
   {
      nameKey: 'events.store.name',
      descriptionKey: 'events.store.description',
      img: storeImage,
   },
   {
      nameKey: 'events.bar.name',
      descriptionKey: 'events.bar.description',
      img: barImage,
   },
   {
      nameKey: 'events.events.name',
      descriptionKey: 'events.events.description',
      img: eventsImage,
   },
]

export const menuData: MenuData = {
   Yemekler: {
      nameKey: 'menu.category.food',
      subcategories: [
         {
            nameKey: 'menu.subcategory.food.appetizers',
            items: [
               {
                  nameKey: 'menu.item.food.appetizers.fume_sandwich.name',
                  descriptionKey:
                     'menu.item.food.appetizers.fume_sandwich.description',
                  price: 320,
                  flavorKey: 'menu.item.food.appetizers.fume_sandwich.flavor',
               },
               {
                  nameKey: 'menu.item.food.appetizers.open_face_tuna.name',
                  descriptionKey:
                     'menu.item.food.appetizers.open_face_tuna.description',
                  flavorKey: 'menu.item.food.appetizers.open_face_tuna.flavor',
                  price: 340,
               },
               {
                  nameKey: 'menu.item.food.appetizers.chicken_popsicle.name',
                  descriptionKey:
                     'menu.item.food.appetizers.chicken_popsicle.description',
                  price: 350,
                  flavorKey:
                     'menu.item.food.appetizers.chicken_popsicle.flavor',
               },

               {
                  nameKey: 'menu.item.food.appetizers.levrek_filango.name',
                  descriptionKey:
                     'menu.item.food.appetizers.levrek_filango.description',
                  price: 440,
                  flavorKey: 'menu.item.food.appetizers.levrek_filango.flavor',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.food.main_courses',
            items: [
               {
                  nameKey: 'menu.item.food.main_courses.orzo_truffle.name',
                  descriptionKey:
                     'menu.item.food.main_courses.orzo_truffle.description',
                  price: 390,
                  flavorKey: 'menu.item.food.main_courses.orzo_truffle.flavor',
               },
               {
                  nameKey: 'menu.item.food.main_courses.topluma_levrek.name',
                  descriptionKey:
                     'menu.item.food.main_courses.topluma_levrek.description',
                  price: 460,
                  flavorKey:
                     'menu.item.food.main_courses.topluma_levrek.flavor',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.food.desserts',
            items: [
               {
                  nameKey: 'menu.item.food.desserts.lemon_cheesecake.name',
                  descriptionKey:
                     'menu.item.food.desserts.lemon_cheesecake.description',
                  price: 250,
                  flavorKey: 'menu.item.food.desserts.lemon_cheesecake.flavor',
               },
               {
                  nameKey: 'menu.item.food.desserts.paris_brest.name',
                  descriptionKey:
                     'menu.item.food.desserts.paris_brest.description',
                  price: 250,
                  flavorKey: 'menu.item.food.desserts.paris_brest.flavor',
               },
            ],
         },
      ],
   },
   Kahveler: {
      nameKey: 'menu.category.coffee',
      subcategories: [
         {
            nameKey: 'menu.subcategory.coffee.hot',
            items: [
               {
                  nameKey: 'menu.item.coffee.hot.espresso.name',
                  descriptionKey: 'menu.item.coffee.hot.espresso.description',
                  price: 100,
                  maxPrice: 130,
                  flavorKey: 'menu.item.coffee.hot.espresso.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.americano.name',
                  descriptionKey: 'menu.item.coffee.hot.americano.description',
                  price: 130,
                  flavorKey: 'menu.item.coffee.hot.americano.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.coffee_latte.name',
                  descriptionKey:
                     'menu.item.coffee.hot.coffee_latte.description',
                  price: 160,
                  flavorKey: 'menu.item.coffee.hot.coffee_latte.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.cappuccino.name',
                  descriptionKey: 'menu.item.coffee.hot.cappuccino.description',
                  price: 160,
                  flavorKey: 'menu.item.coffee.hot.cappuccino.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.flat_white.name',
                  descriptionKey: 'menu.item.coffee.hot.flat_white.description',
                  price: 165,
                  flavorKey: 'menu.item.coffee.hot.flat_white.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.cortado.name',
                  descriptionKey: 'menu.item.coffee.hot.cortado.description',
                  price: 155,
                  flavorKey: 'menu.item.coffee.hot.cortado.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.turkish_coffee.name',
                  descriptionKey:
                     'menu.item.coffee.hot.turkish_coffee.description',
                  price: 140,
                  flavorKey: 'menu.item.coffee.hot.turkish_coffee.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.filter_coffee.name',
                  descriptionKey:
                     'menu.item.coffee.hot.filter_coffee.description',
                  price: 130,
                  flavorKey: 'menu.item.coffee.hot.filter_coffee.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.hot.tea.name',
                  descriptionKey: 'menu.item.coffee.hot.tea.description',
                  price: 50,
                  flavorKey: 'menu.item.coffee.hot.tea.flavor',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.coffee.cold',
            items: [
               {
                  nameKey: 'menu.item.coffee.cold.ice_americano.name',
                  descriptionKey:
                     'menu.item.coffee.cold.ice_americano.description',
                  price: 130,
                  flavorKey: 'menu.item.coffee.cold.ice_americano.flavor',
               },
               {
                  nameKey: 'menu.item.coffee.cold.ice_latte.name',
                  descriptionKey: 'menu.item.coffee.cold.ice_latte.description',
                  price: 175,
                  flavorKey: 'menu.item.coffee.cold.ice_latte.flavor',
               },
            ],
         },
      ],
   },
   Kokteyller: {
      nameKey: 'menu.category.cocktails',
      subcategories: [
         {
            nameKey: 'menu.subcategory.cocktails.classic',
            items: [
               {
                  nameKey: 'menu.item.cocktails.classic.whiskey_sour.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.whiskey_sour.description',
                  flavorKey: 'menu.item.cocktails.classic.whiskey_sour.flavor',
                  price: 420,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.dirty_martini.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.dirty_martini.description',
                  flavorKey: 'menu.item.cocktails.classic.dirty_martini.flavor',
                  price: 420,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.penicillin.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.penicillin.description',
                  flavorKey: 'menu.item.cocktails.classic.penicillin.flavor',
                  price: 440,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.gin_basil.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.gin_basil.description',
                  flavorKey: 'menu.item.cocktails.classic.gin_basil.flavor',
                  price: 450,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.espresso_martini.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.espresso_martini.description',
                  flavorKey:
                     'menu.item.cocktails.classic.espresso_martini.flavor',
                  price: 465,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.dutch_mule.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.dutch_mule.description',
                  flavorKey: 'menu.item.cocktails.classic.dutch_mule.flavor',
                  price: 490,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.mimosa.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.mimosa.description',
                  flavorKey: 'menu.item.cocktails.classic.mimosa.flavor',
                  price: 490,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.bellini.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.bellini.description',
                  flavorKey: 'menu.item.cocktails.classic.bellini.flavor',
                  price: 490,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.tommys_margarita.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.tommys_margarita.description',
                  flavorKey:
                     'menu.item.cocktails.classic.tommys_margarita.flavor',
                  price: 525,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.paloma.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.paloma.description',
                  flavorKey: 'menu.item.cocktails.classic.paloma.flavor',
                  price: 565,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.negroni.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.negroni.description',
                  flavorKey: 'menu.item.cocktails.classic.negroni.flavor',
                  price: 570,
               },
               {
                  nameKey: 'menu.item.cocktails.classic.old_cuban.name',
                  descriptionKey:
                     'menu.item.cocktails.classic.old_cuban.description',
                  flavorKey: 'menu.item.cocktails.classic.old_cuban.flavor',
                  price: 675,
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.cocktails.twisted',
            items: [
               {
                  nameKey:
                     'menu.item.cocktails.twisted.cool_franco_spritz.name',
                  descriptionKey:
                     'menu.item.cocktails.twisted.cool_franco_spritz.description',
                  flavorKey:
                     'menu.item.cocktails.twisted.cool_franco_spritz.flavor',
                  price: 550,
               },
               {
                  nameKey: 'menu.item.cocktails.twisted.bobo_star_martini.name',
                  descriptionKey:
                     'menu.item.cocktails.twisted.bobo_star_martini.description',
                  flavorKey:
                     'menu.item.cocktails.twisted.bobo_star_martini.flavor',
                  price: 490,
               },
               {
                  nameKey: 'menu.item.cocktails.twisted.cosalinha.name',
                  descriptionKey:
                     'menu.item.cocktails.twisted.cosalinha.description',
                  flavorKey: 'menu.item.cocktails.twisted.cosalinha.flavor',
                  price: 490,
               },
               {
                  nameKey:
                     'menu.item.cocktails.twisted.butterfly_daiquiri.name',
                  descriptionKey:
                     'menu.item.cocktails.twisted.butterfly_daiquiri.description',
                  flavorKey:
                     'menu.item.cocktails.twisted.butterfly_daiquiri.flavor',
                  price: 560,
               },
               {
                  nameKey: 'menu.item.cocktails.twisted.rock_n_roll.name',
                  descriptionKey:
                     'menu.item.cocktails.twisted.rock_n_roll.description',
                  flavorKey: 'menu.item.cocktails.twisted.rock_n_roll.flavor',
                  price: 580,
               },
               {
                  nameKey: 'menu.item.cocktails.twisted.melon_garibaldi.name',
                  descriptionKey:
                     'menu.item.cocktails.twisted.melon_garibaldi.description',
                  flavorKey:
                     'menu.item.cocktails.twisted.melon_garibaldi.flavor',
                  price: 570,
               },
               {
                  nameKey: 'menu.item.cocktails.twisted.cane_coco.name',
                  descriptionKey:
                     'menu.item.cocktails.twisted.cane_coco.description',
                  flavorKey: 'menu.item.cocktails.twisted.cane_coco.flavor',
                  price: 610,
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.cocktails.signature',
            items: [
               {
                  nameKey: 'menu.item.cocktails.signature.02.name',
                  descriptionKey:
                     'menu.item.cocktails.signature.02.description',
                  flavorKey: 'menu.item.cocktails.signature.02.flavor',
                  price: 490,
               },
               {
                  nameKey: 'menu.item.cocktails.signature.monik.name',
                  descriptionKey:
                     'menu.item.cocktails.signature.monik.description',
                  flavorKey: 'menu.item.cocktails.signature.monik.flavor',
                  price: 530,
               },
               {
                  nameKey: 'menu.item.cocktails.signature.07.name',
                  descriptionKey:
                     'menu.item.cocktails.signature.07.description',
                  flavorKey: 'menu.item.cocktails.signature.07.flavor',
                  price: 560,
               },
               {
                  nameKey: 'menu.item.cocktails.signature.05.name',
                  descriptionKey:
                     'menu.item.cocktails.signature.05.description',
                  flavorKey: 'menu.item.cocktails.signature.05.flavor',
                  price: 590,
               },
               {
                  nameKey: 'menu.item.cocktails.signature.06.name',
                  descriptionKey:
                     'menu.item.cocktails.signature.06.description',
                  flavorKey: 'menu.item.cocktails.signature.06.flavor',
                  price: 590,
               },
               {
                  nameKey: 'menu.item.cocktails.signature.01.name',
                  descriptionKey:
                     'menu.item.cocktails.signature.01.description',
                  flavorKey: 'menu.item.cocktails.signature.01.flavor',
                  price: 610,
               },
            ],
         },
      ],
   },
   Viskiler: {
      nameKey: 'menu.category.whiskey',
      subcategories: [
         {
            nameKey: 'menu.subcategory.whiskey.blended',
            items: [
               {
                  nameKey: 'menu.item.whiskey.blended.dimple_golden.name',
                  descriptionKey:
                     'menu.item.whiskey.blended.dimple_golden.description',
                  price: 235,
                  maxPrice: 470,
                  flavorKey: 'menu.item.whiskey.blended.dimple_golden.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.blended.jw_black.name',
                  descriptionKey:
                     'menu.item.whiskey.blended.jw_black.description',
                  price: 300,
                  maxPrice: 600,
                  flavorKey: 'menu.item.whiskey.blended.jw_black.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.blended.jw_double_black.name',
                  descriptionKey:
                     'menu.item.whiskey.blended.jw_double_black.description',
                  price: 365,
                  maxPrice: 730,
                  flavorKey: 'menu.item.whiskey.blended.jw_double_black.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.blended.jw_18.name',
                  descriptionKey: 'menu.item.whiskey.blended.jw_18.description',
                  price: 600,
                  maxPrice: 1200,
                  flavorKey: 'menu.item.whiskey.blended.jw_18.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.blended.jw_blue.name',
                  descriptionKey:
                     'menu.item.whiskey.blended.jw_blue.description',
                  price: 1500,
                  maxPrice: 3000,
                  flavorKey: 'menu.item.whiskey.blended.jw_blue.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.blended.jw_king_george.name',
                  descriptionKey:
                     'menu.item.whiskey.blended.jw_king_george.description',
                  price: 3500,
                  maxPrice: 7000,
                  flavorKey: 'menu.item.whiskey.blended.jw_king_george.flavor',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.whiskey.single',
            items: [
               {
                  nameKey: 'menu.item.whiskey.single.singleton_12.name',
                  descriptionKey:
                     'menu.item.whiskey.single.singleton_12.description',
                  price: 270,
                  maxPrice: 540,
                  flavorKey: 'menu.item.whiskey.single.singleton_12.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.ardbeg.name',
                  descriptionKey: 'menu.item.whiskey.single.ardbeg.description',
                  price: 275,
                  maxPrice: 550,
                  flavorKey: 'menu.item.whiskey.single.ardbeg.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.glenmorangie.name',
                  descriptionKey:
                     'menu.item.whiskey.single.glenmorangie.description',
                  price: 350,
                  maxPrice: 700,
                  flavorKey: 'menu.item.whiskey.single.glenmorangie.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.cardhu_gold.name',
                  descriptionKey:
                     'menu.item.whiskey.single.cardhu_gold.description',
                  price: 365,
                  maxPrice: 730,
                  flavorKey: 'menu.item.whiskey.single.cardhu_gold.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.talisker.name',
                  descriptionKey:
                     'menu.item.whiskey.single.talisker.description',
                  price: 400,
                  maxPrice: 800,
                  flavorKey: 'menu.item.whiskey.single.talisker.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.caol_ila.name',
                  descriptionKey:
                     'menu.item.whiskey.single.caol_ila.description',
                  price: 450,
                  maxPrice: 900,
                  flavorKey: 'menu.item.whiskey.single.caol_ila.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.singleton_15.name',
                  descriptionKey:
                     'menu.item.whiskey.single.singleton_15.description',
                  price: 470,
                  maxPrice: 940,
                  flavorKey: 'menu.item.whiskey.single.singleton_15.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.cardhu_15.name',
                  descriptionKey:
                     'menu.item.whiskey.single.cardhu_15.description',
                  price: 540,
                  maxPrice: 1080,
                  flavorKey: 'menu.item.whiskey.single.cardhu_15.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.nikka_malt.name',
                  descriptionKey:
                     'menu.item.whiskey.single.nikka_malt.description',
                  price: 650,
                  maxPrice: 1300,
                  flavorKey: 'menu.item.whiskey.single.nikka_malt.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.nikka_grain.name',
                  descriptionKey:
                     'menu.item.whiskey.single.nikka_grain.description',
                  price: 650,
                  maxPrice: 1300,
                  flavorKey: 'menu.item.whiskey.single.nikka_grain.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.clynelish_14.name',
                  descriptionKey:
                     'menu.item.whiskey.single.clynelish_14.description',
                  price: 700,
                  maxPrice: 1400,
                  flavorKey: 'menu.item.whiskey.single.clynelish_14.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.oban.name',
                  descriptionKey: 'menu.item.whiskey.single.oban.description',
                  price: 880,
                  maxPrice: 1760,
                  flavorKey: 'menu.item.whiskey.single.oban.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.mortlach.name',
                  descriptionKey:
                     'menu.item.whiskey.single.mortlach.description',
                  price: 1030,
                  maxPrice: 2060,
                  flavorKey: 'menu.item.whiskey.single.mortlach.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.clynelish_12.name',
                  descriptionKey:
                     'menu.item.whiskey.single.clynelish_12.description',
                  price: 1400,
                  maxPrice: 2800,
                  flavorKey: 'menu.item.whiskey.single.clynelish_12.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.cardhu_16.name',
                  descriptionKey:
                     'menu.item.whiskey.single.cardhu_16.description',
                  price: 1550,
                  maxPrice: 3100,
                  flavorKey: 'menu.item.whiskey.single.cardhu_16.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.single.cameronbridge.name',
                  descriptionKey:
                     'menu.item.whiskey.single.cameronbridge.description',
                  price: 2225,
                  maxPrice: 4450,
                  flavorKey: 'menu.item.whiskey.single.cameronbridge.flavor',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.whiskey.bourbon',
            items: [
               {
                  nameKey: 'menu.item.whiskey.bourbon.jack_daniels.name',
                  descriptionKey:
                     'menu.item.whiskey.bourbon.jack_daniels.description',
                  price: 275,
                  maxPrice: 550,
                  flavorKey: 'menu.item.whiskey.bourbon.jack_daniels.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.bourbon.gentleman_jack.name',
                  descriptionKey:
                     'menu.item.whiskey.bourbon.gentleman_jack.description',
                  price: 300,
                  maxPrice: 600,
                  flavorKey: 'menu.item.whiskey.bourbon.gentleman_jack.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.bourbon.bulleit.name',
                  descriptionKey:
                     'menu.item.whiskey.bourbon.bulleit.description',
                  price: 435,
                  maxPrice: 870,
                  flavorKey: 'menu.item.whiskey.bourbon.bulleit.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.bourbon.woodford.name',
                  descriptionKey:
                     'menu.item.whiskey.bourbon.woodford.description',
                  price: 450,
                  maxPrice: 900,
                  flavorKey: 'menu.item.whiskey.bourbon.woodford.flavor',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.whiskey.irish',
            items: [
               {
                  nameKey: 'menu.item.whiskey.irish.bushmills.name',
                  descriptionKey:
                     'menu.item.whiskey.irish.bushmills.description',
                  price: 275,
                  maxPrice: 550,
                  flavorKey: 'menu.item.whiskey.irish.bushmills.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.irish.bushmills_10.name',
                  descriptionKey:
                     'menu.item.whiskey.irish.bushmills_10.description',
                  price: 375,
                  maxPrice: 750,
                  flavorKey: 'menu.item.whiskey.irish.bushmills_10.flavor',
               },
               {
                  nameKey: 'menu.item.whiskey.irish.bushmills_black.name',
                  descriptionKey:
                     'menu.item.whiskey.irish.bushmills_black.description',
                  price: 400,
                  maxPrice: 800,
                  flavorKey: 'menu.item.whiskey.irish.bushmills_black.flavor',
               },
            ],
         },
      ],
   },
   Şampanyalar: {
      nameKey: 'menu.category.champagne',
      items: [
         {
            nameKey: 'menu.item.champagne.chandon_garden.name',
            descriptionKey: 'menu.item.champagne.chandon_garden.description',
            flavorKey: 'menu.item.champagne.chandon_garden.flavor',
            price: 550,
            maxPrice: 3200,
         },
         {
            nameKey: 'menu.item.champagne.chandon_brut.name',
            descriptionKey: 'menu.item.champagne.chandon_brut.description',
            flavorKey: 'menu.item.champagne.chandon_brut.flavor',
            price: 550,
            maxPrice: 3200,
         },
         {
            nameKey: 'menu.item.champagne.ruffino.name',
            descriptionKey: 'menu.item.champagne.ruffino.description',
            flavorKey: 'menu.item.champagne.ruffino.flavor',
            price: 750,
            maxPrice: 3500,
         },
         {
            nameKey: 'menu.item.champagne.moet_imperial.name',
            descriptionKey: 'menu.item.champagne.moet_imperial.description',
            flavorKey: 'menu.item.champagne.moet_imperial.flavor',
            price: 10000,
         },
         {
            nameKey: 'menu.item.champagne.moet_rose.name',
            descriptionKey: 'menu.item.champagne.moet_rose.description',
            flavorKey: 'menu.item.champagne.moet_rose.flavor',
            price: 12000,
         },
      ],
   },
   Şaraplar: {
      nameKey: 'menu.category.wine',
      subcategories: [
         {
            nameKey: 'menu.subcategory.wine.red',
            items: [
               {
                  nameKey: 'menu.item.wine.red.vinkara_okuzgozu.name',
                  descriptionKey:
                     'menu.item.wine.red.vinkara_okuzgozu.description',
                  flavorKey: 'menu.item.wine.red.vinkara_okuzgozu.flavor',
                  price: 240,
                  maxPrice: 1100,
               },
               {
                  nameKey: 'menu.item.wine.red.suvla_papaz.name',
                  descriptionKey: 'menu.item.wine.red.suvla_papaz.description',
                  flavorKey: 'menu.item.wine.red.suvla_papaz.flavor',
                  price: 300,
                  maxPrice: 1400,
               },
               {
                  nameKey: 'menu.item.wine.red.suvla_reserve.name',
                  descriptionKey:
                     'menu.item.wine.red.suvla_reserve.description',
                  flavorKey: 'menu.item.wine.red.suvla_reserve.flavor',
                  price: 5800,
               },
               {
                  nameKey: 'menu.item.wine.red.kayra_terra.name',
                  descriptionKey: 'menu.item.wine.red.kayra_terra.description',
                  flavorKey: 'menu.item.wine.red.kayra_terra.flavor',
                  price: '',
                  maxPrice: '',
               },
               {
                  nameKey: 'menu.item.wine.red.cielo_merlot.name',
                  descriptionKey: 'menu.item.wine.red.cielo_merlot.description',
                  flavorKey: 'menu.item.wine.red.cielo_merlot.flavor',
                  price: '',
                  maxPrice: '',
               },
               {
                  nameKey: 'menu.item.wine.red.cielo_cabernet.name',
                  descriptionKey:
                     'menu.item.wine.red.cielo_cabernet.description',
                  flavorKey: 'menu.item.wine.red.cielo_cabernet.flavor',
                  price: '',
                  maxPrice: '',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.wine.white',
            items: [
               {
                  nameKey: 'menu.item.wine.white.vinkara_bornova.name',
                  descriptionKey:
                     'menu.item.wine.white.vinkara_bornova.description',
                  flavorKey: 'menu.item.wine.white.vinkara_bornova.flavor',
                  price: 240,
                  maxPrice: 1100,
               },
               {
                  nameKey: 'menu.item.wine.white.suvla_narince.name',
                  descriptionKey:
                     'menu.item.wine.white.suvla_narince.description',
                  flavorKey: 'menu.item.wine.white.suvla_narince.flavor',
                  price: 340,
                  maxPrice: 1600,
               },
               {
                  nameKey: 'menu.item.wine.white.suvla_chardonnay.name',
                  descriptionKey:
                     'menu.item.wine.white.suvla_chardonnay.description',
                  flavorKey: 'menu.item.wine.white.suvla_chardonnay.flavor',
                  price: 2480,
               },
               {
                  nameKey: 'menu.item.wine.white.kayra_allure_crispy.name',
                  descriptionKey:
                     'menu.item.wine.white.kayra_allure_crispy.description',
                  flavorKey: 'menu.item.wine.white.kayra_allure_crispy.flavor',
                  price: '',
                  maxPrice: '',
               },
               {
                  nameKey: 'menu.item.wine.white.kayra_allure_sauvignon.name',
                  descriptionKey:
                     'menu.item.wine.white.kayra_allure_sauvignon.description',
                  flavorKey:
                     'menu.item.wine.white.kayra_allure_sauvignon.flavor',
                  price: '',
                  maxPrice: '',
               },
               {
                  nameKey: 'menu.item.wine.white.cielo_pinot.name',
                  descriptionKey:
                     'menu.item.wine.white.cielo_pinot.description',
                  flavorKey: 'menu.item.wine.white.cielo_pinot.flavor',
                  price: '',
                  maxPrice: '',
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.wine.rose',
            items: [
               {
                  nameKey: 'menu.item.wine.rose_blush.vinkara_minoj.name',
                  descriptionKey:
                     'menu.item.wine.rose_blush.vinkara_minoj.description',
                  flavorKey: 'menu.item.wine.rose_blush.vinkara_minoj.flavor',
                  price: 240,
                  maxPrice: 1100,
               },
               {
                  nameKey: 'menu.item.wine.rose_blush.suvla_karasakiz.name',
                  descriptionKey:
                     'menu.item.wine.rose_blush.suvla_karasakiz.description',
                  flavorKey: 'menu.item.wine.rose_blush.suvla_karasakiz.flavor',
                  price: 325,
                  maxPrice: 1500,
               },
               {
                  nameKey: 'menu.item.wine.rose_blush.cielo_blush.name',
                  descriptionKey:
                     'menu.item.wine.rose_blush.cielo_blush.description',
                  flavorKey: 'menu.item.wine.rose_blush.cielo_blush.flavor',
                  price: '',
                  maxPrice: '',
               },
               {
                  nameKey: 'menu.item.wine.rose_blush.cielo_rose.name',
                  descriptionKey:
                     'menu.item.wine.rose_blush.cielo_rose.description',
                  flavorKey: 'menu.item.wine.rose_blush.cielo_rose.flavor',
                  price: '',
                  maxPrice: '',
               },
               {
                  nameKey: 'menu.item.wine.rose_blush.antioche_summer.name',
                  descriptionKey:
                     'menu.item.wine.rose_blush.antioche_summer.description',
                  flavorKey: 'menu.item.wine.rose_blush.antioche_summer.flavor',
                  price: 450,
                  maxPrice: 2000,
               },
            ],
         },
         {
            nameKey: 'menu.subcategory.wine.dark_rose',
            items: [
               {
                  nameKey: 'menu.item.wine.dark_rose.suvla_clairet.name',
                  descriptionKey:
                     'menu.item.wine.dark_rose.suvla_clairet.description',
                  flavorKey: 'menu.item.wine.dark_rose.suvla_clairet.flavor',
                  price: 2250,
               },
            ],
         },
      ],
   },
   Gin: {
      nameKey: 'menu.category.gin',
      items: [
         {
            nameKey: 'menu.item.gin.mosaik.name',
            descriptionKey: 'menu.item.gin.mosaik.description',
            flavorKey: 'menu.item.gin.mosaik.flavor',
            price: 330,
         },
         {
            nameKey: 'menu.item.gin.tanqueray_london.name',
            descriptionKey: 'menu.item.gin.tanqueray_london.description',
            flavorKey: 'menu.item.gin.tanqueray_london.flavor',
            price: 350,
         },
         {
            nameKey: 'menu.item.gin.tanqueray_ten.name',
            descriptionKey: 'menu.item.gin.tanqueray_ten.description',
            flavorKey: 'menu.item.gin.tanqueray_ten.flavor',
            price: 350,
         },
         {
            nameKey: 'menu.item.gin.nikka_coffey.name',
            descriptionKey: 'menu.item.gin.nikka_coffey.description',
            flavorKey: 'menu.item.gin.nikka_coffey.flavor',
            price: 410,
         },
         {
            nameKey: 'menu.item.gin.hendricks.name',
            descriptionKey: 'menu.item.gin.hendricks.description',
            flavorKey: 'menu.item.gin.hendricks.flavor',
            price: 550,
         },
      ],
   },
   Tekila: {
      nameKey: 'menu.category.tequila',
      items: [
         {
            nameKey: 'menu.item.tequila.volcan.name',
            descriptionKey: 'menu.item.tequila.volcan.description',
            flavorKey: 'menu.item.tequila.volcan.flavor',
            price: 340,
         },
         {
            nameKey: 'menu.item.tequila.casamigos_blanco.name',
            descriptionKey: 'menu.item.tequila.casamigos_blanco.description',
            flavorKey: 'menu.item.tequila.casamigos_blanco.flavor',
            price: 350,
         },
         {
            nameKey: 'menu.item.tequila.casamigos_reposado.name',
            descriptionKey: 'menu.item.tequila.casamigos_reposado.description',
            flavorKey: 'menu.item.tequila.casamigos_reposado.flavor',
            price: 430,
         },
         {
            nameKey: 'menu.item.tequila.casamigos_anejo.name',
            descriptionKey: 'menu.item.tequila.casamigos_anejo.description',
            flavorKey: 'menu.item.tequila.casamigos_anejo.flavor',
            price: 460,
         },
         {
            nameKey: 'menu.item.tequila.casamigos_mezcal.name',
            descriptionKey: 'menu.item.tequila.casamigos_mezcal.description',
            flavorKey: 'menu.item.tequila.casamigos_mezcal.flavor',
            price: 485,
         },
         {
            nameKey: 'menu.item.tequila.don_julio_blanco.name',
            descriptionKey: 'menu.item.tequila.don_julio_blanco.description',
            flavorKey: 'menu.item.tequila.don_julio_blanco.flavor',
            price: 470,
         },
         {
            nameKey: 'menu.item.tequila.don_julio_reposado.name',
            descriptionKey: 'menu.item.tequila.don_julio_reposado.description',
            flavorKey: 'menu.item.tequila.don_julio_reposado.flavor',
            price: 520,
         },
         {
            nameKey: 'menu.item.tequila.don_julio_1942.name',
            descriptionKey: 'menu.item.tequila.don_julio_1942.description',
            flavorKey: 'menu.item.tequila.don_julio_1942.flavor',
            price: 2600,
         },
      ],
   },
   Vodka: {
      nameKey: 'menu.category.vodka',
      items: [
         {
            nameKey: 'menu.item.vodka.ketel_one.name',
            descriptionKey: 'menu.item.vodka.ketel_one.description',
            flavorKey: 'menu.item.vodka.ketel_one.flavor',
            price: 350,
         },
         {
            nameKey: 'menu.item.vodka.belvedere.name',
            descriptionKey: 'menu.item.vodka.belvedere.description',
            flavorKey: 'menu.item.vodka.belvedere.flavor',
            price: 440,
         },
         {
            nameKey: 'menu.item.vodka.beluga.name',
            descriptionKey: 'menu.item.vodka.beluga.description',
            flavorKey: 'menu.item.vodka.beluga.flavor',
            price: 520,
         },
         {
            nameKey: 'menu.item.vodka.nikka_coffey.name',
            descriptionKey: 'menu.item.vodka.nikka_coffey.description',
            flavorKey: 'menu.item.vodka.nikka_coffey.flavor',
            price: '',
         },
      ],
   },
   Rum: {
      nameKey: 'menu.category.rum',
      items: [
         {
            nameKey: 'menu.item.rum.captain_white.name',
            descriptionKey: 'menu.item.rum.captain_white.description',
            flavorKey: 'menu.item.rum.captain_white.flavor',
            price: 250,
         },
         {
            nameKey: 'menu.item.rum.captain_gold.name',
            descriptionKey: 'menu.item.rum.captain_gold.description',
            flavorKey: 'menu.item.rum.captain_gold.flavor',
            price: 250,
         },
         {
            nameKey: 'menu.item.rum.zacapa.name',
            descriptionKey: 'menu.item.rum.zacapa.description',
            flavorKey: 'menu.item.rum.zacapa.flavor',
            price: 520,
         },
      ],
   },
   Konyak: {
      nameKey: 'menu.category.cognac',
      items: [
         {
            nameKey: 'menu.item.cognac.hennesy_vs.name',
            descriptionKey: 'menu.item.cognac.hennesy_vs.description',
            flavorKey: 'menu.item.cognac.hennesy_vs.flavor',
            price: '',
         },
         {
            nameKey: 'menu.item.cognac.hennesy_vsop.name',
            descriptionKey: 'menu.item.cognac.hennesy_vsop.description',
            flavorKey: 'menu.item.cognac.hennesy_vsop.flavor',
            price: 600,
         },
      ],
   },
   Vermouth: {
      nameKey: 'menu.category.vermouth',
      items: [
         {
            nameKey: 'menu.item.vermouth.lillet_blanc.name',
            descriptionKey: 'menu.item.vermouth.lillet_blanc.description',
            flavorKey: 'menu.item.vermouth.lillet_blanc.flavor',
            price: 500,
         },
         {
            nameKey: 'menu.item.vermouth.lillet_rose.name',
            descriptionKey: 'menu.item.vermouth.lillet_rose.description',
            flavorKey: 'menu.item.vermouth.lillet_rose.flavor',
            price: 500,
         },
         {
            nameKey: 'menu.item.vermouth.garrone_bianco.name',
            descriptionKey: 'menu.item.vermouth.garrone_bianco.description',
            flavorKey: 'menu.item.vermouth.garrone_bianco.flavor',
            price: 520,
         },
         {
            nameKey: 'menu.item.vermouth.garrone_dry.name',
            descriptionKey: 'menu.item.vermouth.garrone_dry.description',
            flavorKey: 'menu.item.vermouth.garrone_dry.flavor',
            price: 520,
         },
         {
            nameKey: 'menu.item.vermouth.garrone_rosso.name',
            descriptionKey: 'menu.item.vermouth.garrone_rosso.description',
            flavorKey: 'menu.item.vermouth.garrone_rosso.flavor',
            price: 520,
         },
      ],
   },
   Aquavit: {
      nameKey: 'menu.category.aquavit',
      items: [
         {
            nameKey: 'menu.item.aquavit.aalborg.name',
            descriptionKey: 'menu.item.aquavit.aalborg.description',
            flavorKey: 'menu.item.aquavit.aalborg.flavor',
            price: 315,
         },
      ],
   },
   Likörler: {
      nameKey: 'menu.category.liqueur',
      items: [
         {
            nameKey: 'menu.item.liqueur.bottega_limoncino.name',
            descriptionKey: 'menu.item.liqueur.bottega_limoncino.description',
            flavorKey: 'menu.item.liqueur.bottega_limoncino.flavor',
            price: 220,
         },
         {
            nameKey: 'menu.item.liqueur.bottega_melon.name',
            descriptionKey: 'menu.item.liqueur.bottega_melon.description',
            flavorKey: 'menu.item.liqueur.bottega_melon.flavor',
            price: 200,
         },
         {
            nameKey: 'menu.item.liqueur.bottega_amaro.name',
            descriptionKey: 'menu.item.liqueur.bottega_amaro.description',
            flavorKey: 'menu.item.liqueur.bottega_amaro.flavor',
            price: 220,
         },
         {
            nameKey: 'menu.item.liqueur.bottega_amaretto.name',
            descriptionKey: 'menu.item.liqueur.bottega_amaretto.description',
            flavorKey: 'menu.item.liqueur.bottega_amaretto.flavor',
            price: 200,
         },
         {
            nameKey: 'menu.item.liqueur.bottega_pistachio.name',
            descriptionKey: 'menu.item.liqueur.bottega_pistachio.description',
            flavorKey: 'menu.item.liqueur.bottega_pistachio.flavor',
            price: 220,
         },
         {
            nameKey: 'menu.item.liqueur.bottega_sambuca.name',
            descriptionKey: 'menu.item.liqueur.bottega_sambuca.description',
            flavorKey: 'menu.item.liqueur.bottega_sambuca.flavor',
            price: 260,
         },
         {
            nameKey: 'menu.item.liqueur.jagermeister.name',
            descriptionKey: 'menu.item.liqueur.jagermeister.description',
            flavorKey: 'menu.item.liqueur.jagermeister.flavor',
            price: 210,
         },
         {
            nameKey: 'menu.item.liqueur.jagermeister_manifes.name',
            descriptionKey:
               'menu.item.liqueur.jagermeister_manifes.description',
            flavorKey: 'menu.item.liqueur.jagermeister_manifes.flavor',
            price: 260,
         },
         {
            nameKey: 'menu.item.liqueur.jagermeister_cold_brew.name',
            descriptionKey:
               'menu.item.liqueur.jagermeister_cold_brew.description',
            flavorKey: 'menu.item.liqueur.jagermeister_cold_brew.flavor',
            price: 240,
         },
         {
            nameKey: 'menu.item.liqueur.garrone_triple_sec.name',
            descriptionKey: 'menu.item.liqueur.garrone_triple_sec.description',
            flavorKey: 'menu.item.liqueur.garrone_triple_sec.flavor',
            price: 300,
         },
         {
            nameKey: 'menu.item.liqueur.malibu.name',
            descriptionKey: 'menu.item.liqueur.malibu.description',
            flavorKey: 'menu.item.liqueur.malibu.flavor',
            price: 200,
         },
         {
            nameKey: 'menu.item.liqueur.kahlua.name',
            descriptionKey: 'menu.item.liqueur.kahlua.description',
            flavorKey: 'menu.item.liqueur.kahlua.flavor',
            price: 240,
         },
         {
            nameKey: 'menu.item.liqueur.campari.name',
            descriptionKey: 'menu.item.liqueur.campari.description',
            flavorKey: 'menu.item.liqueur.campari.flavor',
            price: 240,
         },
         {
            nameKey: 'menu.item.liqueur.koskenkorva.name',
            descriptionKey: 'menu.item.liqueur.koskenkorva.description',
            flavorKey: 'menu.item.liqueur.koskenkorva.flavor',
            price: 200,
         },
         {
            nameKey: 'menu.item.liqueur.baileys.name',
            descriptionKey: 'menu.item.liqueur.baileys.description',
            flavorKey: 'menu.item.liqueur.baileys.flavor',
            price: 200,
         },
         {
            nameKey: 'menu.item.liqueur.aperol.name',
            descriptionKey: 'menu.item.liqueur.aperol.description',
            flavorKey: 'menu.item.liqueur.aperol.flavor',
            price: 550,
         },
      ],
   },
   Bira: {
      nameKey: 'menu.category.beer',
      items: [
         {
            nameKey: 'menu.item.beer.miller.name',
            descriptionKey: 'menu.item.beer.miller.description',
            flavorKey: 'menu.item.beer.miller.flavor',
            price: 250,
         },
         {
            nameKey: 'menu.item.beer.heineken.name',
            descriptionKey: 'menu.item.beer.heineken.description',
            flavorKey: 'menu.item.beer.heineken.flavor',
            price: 250,
         },
         {
            nameKey: 'menu.item.beer.blanc.name',
            descriptionKey: 'menu.item.beer.blanc.description',
            flavorKey: 'menu.item.beer.blanc.flavor',
            price: 250,
         },
         {
            nameKey: 'menu.item.beer.hoegaarden.name',
            descriptionKey: 'menu.item.beer.hoegaarden.description',
            flavorKey: 'menu.item.beer.hoegaarden.flavor',
            price: 300,
         },
      ],
   },
   Tonicler: {
      nameKey: 'menu.category.tonic',
      items: [
         {
            nameKey: 'menu.item.tonic.thomas_henry.name',
            descriptionKey: 'menu.item.tonic.thomas_henry.description',
            flavorKey: 'menu.item.tonic.thomas_henry.flavor',
            price: 150,
         },
         {
            nameKey: 'menu.item.tonic.thomas_henry_pink.name',
            descriptionKey: 'menu.item.tonic.thomas_henry_pink.description',
            flavorKey: 'menu.item.tonic.thomas_henry_pink.flavor',
            price: 150,
         },
         {
            nameKey: 'menu.item.tonic.thomas_henry_mango.name',
            descriptionKey: 'menu.item.tonic.thomas_henry_mango.description',
            flavorKey: 'menu.item.tonic.thomas_henry_mango.flavor',
            price: 150,
         },
         {
            nameKey: 'menu.item.tonic.thomas_henry_ginger.name',
            descriptionKey: 'menu.item.tonic.thomas_henry_ginger.description',
            flavorKey: 'menu.item.tonic.thomas_henry_ginger.flavor',
            price: 150,
         },
      ],
   },
   'Soft İçecekler': {
      nameKey: 'menu.category.soft',
      items: [
         {
            nameKey: 'menu.item.soft.uludag_su.name',
            descriptionKey: 'menu.item.soft.uludag_su.description',
            flavorKey: 'menu.item.soft.uludag_su.flavor',
            price: 40,
         },
         {
            nameKey: 'menu.item.soft.uludag_soda.name',
            descriptionKey: 'menu.item.soft.uludag_soda.description',
            flavorKey: 'menu.item.soft.uludag_soda.flavor',
            price: 40,
         },
         {
            nameKey: 'menu.item.soft.uludag_premium.name',
            descriptionKey: 'menu.item.soft.uludag_premium.description',
            flavorKey: 'menu.item.soft.uludag_premium.flavor',
            price: 65,
         },
         {
            nameKey: 'menu.item.soft.coca_cola.name',
            descriptionKey: 'menu.item.soft.coca_cola.description',
            flavorKey: 'menu.item.soft.coca_cola.flavor',
            price: 90,
         },
         {
            nameKey: 'menu.item.soft.coca_cola_zero.name',
            descriptionKey: 'menu.item.soft.coca_cola_zero.description',
            flavorKey: 'menu.item.soft.coca_cola_zero.flavor',
            price: 90,
         },
         {
            nameKey: 'menu.item.soft.sprite.name',
            descriptionKey: 'menu.item.soft.sprite.description',
            flavorKey: 'menu.item.soft.sprite.flavor',
            price: 95,
         },
         {
            nameKey: 'menu.item.soft.fuse_tea_peach.name',
            descriptionKey: 'menu.item.soft.fuse_tea_peach.description',
            flavorKey: 'menu.item.soft.fuse_tea_peach.flavor',
            price: 95,
         },
         {
            nameKey: 'menu.item.soft.fuse_tea_lemon.name',
            descriptionKey: 'menu.item.soft.fuse_tea_lemon.description',
            flavorKey: 'menu.item.soft.fuse_tea_lemon.flavor',
            price: 95,
         },
         {
            nameKey: 'menu.item.soft.redbull.name',
            descriptionKey: 'menu.item.soft.redbull.description',
            flavorKey: 'menu.item.soft.redbull.flavor',
            price: 140,
         },
         {
            nameKey: 'menu.item.soft.orange_juice.name',
            descriptionKey: 'menu.item.soft.orange_juice.description',
            flavorKey: 'menu.item.soft.orange_juice.flavor',
            price: 90,
         },
      ],
   },
}
