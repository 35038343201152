import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Navbar.css'
import Logo from '../../assets/bobofranco.png'
import { useGlobalContext } from '../../GlobalContext'

const Navbar = ({ iconColor = 'white' }) => {
   const [isOpen, setIsOpen] = useState(false)
   const { pathname } = useLocation()
   const [iconColorState, setIconColor] = useState(iconColor)
   const [isSticky, setIsSticky] = useState(true)
   const [backgroundColor, setBackgroundColor] = useState('transparent')
   const [showLogo, setShowLogo] = useState(false)
   const { language, setLanguage, t } = useGlobalContext()

   // Lock body scroll when menu is open
   useEffect(() => {
      if (isOpen) {
         document.body.style.overflow = 'hidden'
      } else {
         document.body.style.overflow = 'unset'
      }
      return () => {
         document.body.style.overflow = 'unset'
      }
   }, [isOpen])

   const toggleMenu = () => {
      setIsOpen(!isOpen)
   }

   const toggleLanguage = (newLang: 'en' | 'tr') => {
      setLanguage(newLang)
   }

   useEffect(() => {
      if (isOpen) {
         setIconColor('white')
      } else if (pathname === '/about-us' || pathname === '/gallery') {
         setIconColor('black')
      } else {
         setIconColor('white')
      }
   }, [pathname, isOpen])

   const menuItems = [
      { name: t('nav.home'), path: '/' },
      { name: t('nav.about'), path: '/about-us' },
      { name: t('nav.gallery'), path: '/gallery' },
   ]

   useEffect(() => {
      if (pathname === '/') {
         setBackgroundColor('transparent')
         setIsSticky(false)
         setShowLogo(false)
      } else {
         setBackgroundColor('#eee3d1')
         setIsSticky(true)
         setShowLogo(true)
      }
   }, [pathname])

   return (
      <nav
         className={`navbar ${isSticky ? 'sticky' : ''} ${
            isOpen ? 'open' : ''
         }`}
         style={{ backgroundColor }}>
         <div className="navbar-container">
            {/* Menu icon */}
            <div
               className="menu-icon"
               onClick={toggleMenu}
               style={{ color: iconColorState }}>
               <div className="line"></div>
               <div className="line"></div>
               <div className="line"></div>
            </div>

            {/* Center Logo */}
            {showLogo && !isOpen && (
               <Link to="/" className="navbar-logo">
                  <img
                     src={Logo}
                     alt="Logo"
                     width="100%"
                     height="100%"
                     loading="eager"
                     className="object-contain"
                  />
               </Link>
            )}
         </div>

         {/* Full screen menu */}
         <div className={`menu ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <ul onClick={e => e.stopPropagation()}>
               {menuItems.map((item, index) => (
                  <li
                     key={item.path}
                     style={{ transitionDelay: `${(index + 1) * 0.1}s` }}>
                     <Link
                        to={item.path}
                        onClick={toggleMenu}
                        className={pathname === item.path ? 'active' : ''}>
                        {item.name}
                     </Link>
                  </li>
               ))}
               <li className="language-menu-item">
                  <span
                     className={`language-option ${
                        language === 'en' ? 'active' : ''
                     }`}
                     onClick={() => toggleLanguage('en')}>
                     EN
                  </span>
                  <span className="language-separator">|</span>
                  <span
                     className={`language-option ${
                        language === 'tr' ? 'active' : ''
                     }`}
                     onClick={() => toggleLanguage('tr')}>
                     TR
                  </span>
               </li>
            </ul>
         </div>
      </nav>
   )
}

export default Navbar
