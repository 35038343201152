import React, { FC, useState, useEffect } from 'react'
import { useGlobalContext } from '../GlobalContext'
import Menu from '../components/menu.tsx/Menu'
import HeroSection from '../components/HeroSection'
import ViewTransition from '../components/ViewTransition'
import animation from '../helpers/animation'

const Home: FC = () => {
   const { view, setView } = useGlobalContext()
   const [isGrowing, setIsGrowing] = useState(false)
   const [isShrinking, setIsShrinking] = useState(false)

   // Control body overflow during transition
   useEffect(() => {
      if (isGrowing || isShrinking) {
         document.body.style.overflow = 'hidden'
      } else {
         document.body.style.overflow = 'auto'
      }
   }, [isGrowing, isShrinking])

   const handleViewChange = () => {
      setIsGrowing(true)
      setView(view === 'store' ? 'bar' : 'store')
      setTimeout(() => {
         setIsGrowing(false)
         setIsShrinking(true)
         setTimeout(() => {
            setIsShrinking(false)
            // Reset animation classes
            const elements = document.querySelectorAll('.animated')
            elements.forEach(element => {
               element.classList.remove('animated')
               element.classList.add('animate')
            })
            animation()
         }, 2000)
      }, 2000)
   }

   return (
      <div className="relative">
         <ViewTransition
            isGrowing={isGrowing}
            isShrinking={isShrinking}
            isToStore={view === 'store'}
         />
         <HeroSection onViewChange={handleViewChange} />
         <Menu />
      </div>
   )
}

export default Home
