import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import LottieView from 'lottie-react'
import barAnimationData from '../assets/bar_animation.json'
import storeAnimationData from '../assets/store_animation.json'

const growCircle = keyframes`
  0% {
    clip-path: circle(0% at 50% 50%);
  }
  100% {
    clip-path: circle(150% at 50% 50%);
  }
`

const shrinkCircle = keyframes`
  0% {
    clip-path: circle(150% at 50% 50%);
  }
  100% {
    clip-path: circle(0% at 50% 50%);
  }
`

const TransitionWrapper = styled.div<{
   isGrowing: boolean
   isShrinking: boolean
}>`
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background: #eee3d1;
   animation: ${({ isGrowing, isShrinking }) =>
         isGrowing ? growCircle : isShrinking ? shrinkCircle : 'none'}
      2s ease-out forwards;
   display: ${({ isGrowing, isShrinking }) =>
      isGrowing || isShrinking ? 'flex' : 'none'};
   justify-content: center;
   align-items: center;
   z-index: 9999;
   overflow: visible;

   .lottie-container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
   }
`

const LottieContainer = styled.div<{ isToStore: boolean }>`
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   .lottie-animation {
      width: ${({ isToStore }) => (isToStore ? '50vw' : '100vw')} !important;
      height: ${({ isToStore }) => (isToStore ? '50vh' : '100vh')} !important;
   }
`

interface ViewTransitionProps {
   isGrowing: boolean
   isShrinking: boolean
   isToStore: boolean
}

const ViewTransition: FC<ViewTransitionProps> = ({
   isGrowing,
   isShrinking,
   isToStore,
}) => {
   const animationData = isToStore ? storeAnimationData : barAnimationData

   return (
      <TransitionWrapper isGrowing={isGrowing} isShrinking={isShrinking}>
         <LottieContainer isToStore={isToStore}>
            <LottieView
               className="lottie-animation"
               animationData={animationData}
            />
         </LottieContainer>
      </TransitionWrapper>
   )
}

export default ViewTransition
