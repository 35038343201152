import React from 'react'
import styled from 'styled-components'
import { useGlobalContext } from '../../GlobalContext'

const AboutUsContainer = styled.div`
   width: 100%;
   max-width: 1400px;
   margin: 0 auto;
   padding: 2rem;
   box-sizing: border-box;
   min-height: calc(100vh - 80px);
   display: flex;
   flex-direction: column;
   justify-content: center;

   @media (max-width: 768px) {
      padding: 2.5rem 1.5rem 3.5rem;
      justify-content: flex-start;
   }
`

const Header = styled.header`
   text-align: center;
   margin-bottom: 4rem;

   h1 {
      font-size: 4.5rem;
      color: #333;
      margin: 0;
      font-weight: 600;
      position: relative;
      display: inline-block;

      &::after {
         content: '';
         position: absolute;
         bottom: -15px;
         left: 50%;
         transform: translateX(-50%);
         width: 200px;
         height: 2px;
         background: linear-gradient(
            90deg,
            transparent 0%,
            #c8a97e 20%,
            #e0c9a6 50%,
            #c8a97e 80%,
            transparent 100%
         );
         background-size: 200% 100%;
         animation: borderShimmer 3s linear infinite;
      }

      @keyframes borderShimmer {
         0% {
            background-position: 0% center;
         }
         100% {
            background-position: -200% center;
         }
      }

      @media (max-width: 768px) {
         font-size: 3.5rem;
         margin-top: 1.5rem;
         margin-bottom: 0.5rem;

         &::after {
            bottom: -12px;
            width: 160px;
         }
      }

      @media (max-width: 480px) {
         font-size: 2.5rem;
         margin-top: 1rem;

         &::after {
            width: 120px;
         }
      }
   }

   @media (max-width: 768px) {
      margin-bottom: 3.5rem;
   }
`

const Content = styled.div`
   max-width: 900px;
   margin: 0 auto;
   width: 100%;
   line-height: 2;
   color: #333;
   padding: 0 2rem;
   margin-bottom: 2rem;

   @media (max-width: 768px) {
      padding: 0 1.5rem;
      margin-bottom: 3rem;
      line-height: 1.8;
   }
`

interface ParagraphProps {
   delay: number
}

const Paragraph = styled.p<ParagraphProps>`
   font-size: 1.6rem;
   margin-bottom: 2.5rem;
   opacity: 0;
   animation: fadeIn 0.5s ease forwards;
   animation-delay: ${props => props.delay * 0.2}s;
   letter-spacing: 0.3px;
   text-align: center;

   @keyframes fadeIn {
      from {
         opacity: 0;
         transform: translateY(20px);
      }
      to {
         opacity: 1;
         transform: translateY(0);
      }
   }

   @media (max-width: 768px) {
      font-size: 1.4rem;
      margin-bottom: 2rem;
   }

   @media (max-width: 480px) {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
   }

   &:last-child {
      margin-bottom: 0;
   }
`

const AboutUs = () => {
   const { t } = useGlobalContext()

   return (
      <AboutUsContainer>
         <Header>
            <h1>{t('about.title')}</h1>
         </Header>

         <Content>
            <Paragraph delay={0}>{t('about.paragraph1')}</Paragraph>
            <Paragraph delay={1}>{t('about.paragraph2')}</Paragraph>
            <Paragraph delay={2}>{t('about.paragraph3')}</Paragraph>
         </Content>
      </AboutUsContainer>
   )
}

export default AboutUs
