import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable'
import Masonry from 'react-masonry-css'

// Loading animation component
const LoadingSpinner = styled.div`
   width: 50px;
   height: 50px;
   border: 3px solid #f3f3f3;
   border-top: 3px solid #3498db;
   border-radius: 50%;
   animation: spin 1s linear infinite;
   margin: 20px auto;

   @keyframes spin {
      0% {
         transform: rotate(0deg);
      }
      100% {
         transform: rotate(360deg);
      }
   }
`

// Modal component with enhanced features
const Modal = ({
   imgSrc,
   onClose,
   onNext,
   onPrev,
   hasNext,
   hasPrev,
}: {
   imgSrc: string
   onClose: () => void
   onNext: () => void
   onPrev: () => void
   hasNext: boolean
   hasPrev: boolean
}) => {
   const [isLoading, setIsLoading] = useState(true)

   useEffect(() => {
      const handleKeyPress = (e: KeyboardEvent) => {
         if (e.key === 'ArrowRight' && hasNext) onNext()
         if (e.key === 'ArrowLeft' && hasPrev) onPrev()
         if (e.key === 'Escape') onClose()
      }

      window.addEventListener('keydown', handleKeyPress)
      return () => window.removeEventListener('keydown', handleKeyPress)
   }, [onNext, onPrev, onClose, hasNext, hasPrev])

   const handlers = useSwipeable({
      onSwipedLeft: () => {
         if (hasNext) onNext()
      },
      onSwipedRight: () => {
         if (hasPrev) onPrev()
      },
      preventScrollOnSwipe: true,
      trackMouse: true,
   })

   const handleImageLoad = () => {
      setIsLoading(false)
   }

   return (
      <ModalOverlay onClick={onClose}>
         <ModalContent {...handlers}>
            {isLoading && <LoadingSpinner />}
            <ModalImage
               src={imgSrc}
               alt="Expanded view"
               onLoad={handleImageLoad}
               style={{ display: isLoading ? 'none' : 'block' }}
            />
            <NavigationContainer
               onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                  event.stopPropagation()
               }>
               <NavigationButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                     event.stopPropagation()
                     if (hasPrev) onPrev()
                  }}
                  disabled={!hasPrev}
                  aria-label="Previous image"
                  className={hasPrev ? 'active' : ''}>
                  <Arrow>←</Arrow>
               </NavigationButton>
               <NavigationButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                     event.stopPropagation()
                     if (hasNext) onNext()
                  }}
                  disabled={!hasNext}
                  aria-label="Next image"
                  className={hasNext ? 'active' : ''}>
                  <Arrow>→</Arrow>
               </NavigationButton>
            </NavigationContainer>
            <CloseButton onClick={onClose} aria-label="Close modal">
               ×
            </CloseButton>
         </ModalContent>
      </ModalOverlay>
   )
}

// Enhanced styled components
const GalleryContainer = styled.div`
   width: 100%;
   max-width: 1400px;
   margin: 0 auto;
   padding: 4rem 2rem 5rem;
   box-sizing: border-box;
   min-height: calc(100vh - 80px);
   display: flex;
   flex-direction: column;

   @media (max-width: 768px) {
      padding: 3rem 1.5rem 4rem;
   }

   @media (max-width: 480px) {
      padding: 2.5rem 1.5rem 3.5rem;
   }
`

const StyledMasonry = styled(Masonry)`
   display: flex;
   width: 100%;
   margin-left: -1rem;

   .my-masonry-grid_column {
      padding-left: 1rem;
      background-clip: padding-box;
   }
`

const GalleryItem = styled.div`
   cursor: pointer;
   overflow: hidden;
   position: relative;
   border-radius: 12px;
   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   margin-bottom: 1rem;
   background: #f8f8f8;
   width: 100%;

   &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 1;
   }

   img {
      width: 100%;
      height: auto;
      display: block;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
   }

   &:hover {
      transform: translateY(-3px);
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);

      &::before {
         opacity: 1;
      }

      img {
         transform: scale(1.05);
      }
   }
`

const ModalOverlay = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.9);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
   backdrop-filter: blur(5px);
   animation: fadeIn 0.3s ease;

   @keyframes fadeIn {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }
`

const ModalContent = styled.div`
   background-color: transparent;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   width: 90vw;
   height: 90vh;
`

const ModalImage = styled.img`
   max-width: 90vw;
   max-height: 85vh;
   object-fit: contain;
   border-radius: 8px;
   animation: scaleIn 0.3s ease;
   pointer-events: none;

   @keyframes scaleIn {
      from {
         transform: scale(0.95);
         opacity: 0;
      }
      to {
         transform: scale(1);
         opacity: 1;
      }
   }
`

const NavigationContainer = styled.div`
   position: absolute;
   left: 0;
   right: 0;
   top: 50%;
   transform: translateY(-50%);
   display: flex;
   justify-content: space-between;
   padding: 0 20px;
   z-index: 1001;
   pointer-events: none;

   @media (max-width: 768px) {
      padding: 0 10px;
   }
`

const NavigationButton = styled.button`
   background: rgba(255, 255, 255, 0.2);
   border: none;
   border-radius: 50%;
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   transition: all 0.3s ease;
   color: white;
   pointer-events: auto;
   backdrop-filter: blur(5px);

   @media (max-width: 768px) {
      width: 40px;
      height: 40px;
   }

   &.active {
      &:hover {
         background: rgba(255, 255, 255, 0.3);
         transform: scale(1.1);
      }
   }

   &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      transform: none;
   }
`

const Arrow = styled.span`
   font-size: 24px;
   font-weight: bold;

   @media (max-width: 768px) {
      font-size: 20px;
   }
`

const CloseButton = styled.button`
   position: absolute;
   top: 20px;
   right: 20px;
   background: rgba(255, 255, 255, 0.2);
   border: none;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   font-size: 24px;
   color: white;
   transition: all 0.3s ease;
   backdrop-filter: blur(5px);
   z-index: 1002;

   @media (max-width: 768px) {
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
      font-size: 20px;
   }

   &:hover {
      background: rgba(255, 255, 255, 0.3);
      transform: scale(1.1);
   }
`

const Gallery: React.FC = () => {
   const [selectedImage, setSelectedImage] = useState<number | null>(null)
   const [loadedImages, setLoadedImages] = useState<boolean[]>(
      Array(13).fill(false)
   )
   const [sortedImages, setSortedImages] = useState<
      Array<{ src: string; index: number }>
   >([])

   const images = useMemo(
      () =>
         Array.from(
            { length: 13 },
            (_, i) => `/assets/gallery/gallery${i + 1}.jpeg`
         ),
      []
   )

   useEffect(() => {
      const loadImage = (src: string): Promise<HTMLImageElement> => {
         return new Promise(resolve => {
            const img = new Image()
            img.src = src
            img.onload = () => resolve(img)
         })
      }

      const optimizeLayout = async () => {
         const imagePromises = images.map(src => loadImage(src))
         const loadedImages = await Promise.all(imagePromises)

         // Calculate dimensions and sort for optimal puzzle layout
         const imageData = loadedImages.map((img, index) => ({
            src: images[index],
            index,
            aspectRatio: img.height / img.width,
            area: img.width * img.height,
         }))

         // Sort by aspect ratio and area for better puzzle fit
         const sorted = imageData
            .sort((a, b) => {
               // First group by similar aspect ratios
               const aspectRatioGroup =
                  Math.floor(a.aspectRatio * 2) - Math.floor(b.aspectRatio * 2)
               if (aspectRatioGroup !== 0) return aspectRatioGroup

               // Within same aspect ratio group, sort by area
               return b.area - a.area
            })
            .map(({ src, index }) => ({ src, index }))

         setSortedImages(sorted)
      }

      optimizeLayout()
   }, [images])

   const handleImageClick = (index: number) => {
      setSelectedImage(index)
   }

   const closeModal = () => {
      setSelectedImage(null)
   }

   const handleNext = () => {
      if (selectedImage !== null && selectedImage < images.length - 1) {
         setSelectedImage(selectedImage + 1)
      }
   }

   const handlePrev = () => {
      if (selectedImage !== null && selectedImage > 0) {
         setSelectedImage(selectedImage - 1)
      }
   }

   const handleImageLoad = (index: number) => {
      setLoadedImages(prev => {
         const newLoaded = [...prev]
         newLoaded[index] = true
         return newLoaded
      })
   }

   const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1,
   }

   return (
      <GalleryContainer>
         <StyledMasonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {sortedImages.map(({ src, index }) => (
               <GalleryItem key={index} onClick={() => handleImageClick(index)}>
                  {!loadedImages[index] && <LoadingSpinner />}
                  <img
                     src={src}
                     alt={`Gallery image ${index + 1}`}
                     onLoad={() => handleImageLoad(index)}
                     style={{ display: loadedImages[index] ? 'block' : 'none' }}
                  />
               </GalleryItem>
            ))}
         </StyledMasonry>

         {selectedImage !== null && (
            <Modal
               imgSrc={images[selectedImage]}
               onClose={closeModal}
               onNext={handleNext}
               onPrev={handlePrev}
               hasNext={selectedImage < images.length - 1}
               hasPrev={selectedImage > 0}
            />
         )}
      </GalleryContainer>
   )
}

export default Gallery
