import React, { useState, useRef, useEffect } from 'react'
import { menuData, MenuItem, MenuSubcategory } from '../../utils/_DATA'
import './Menu.css'
import { useGlobalContext } from '../../GlobalContext'
import { motion, AnimatePresence } from 'framer-motion'

const Menu: React.FC = () => {
   const { t } = useGlobalContext()
   const [activeCategory, setActiveCategory] = useState<string>(
      t('menu.category.food')
   )
   const [expandedSubcategories, setExpandedSubcategories] = useState<
      Set<string>
   >(new Set())
   const [activeSubcategory, setActiveSubcategory] = useState<string | null>(
      null
   )
   const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
   const subcategorySectionRefs = useRef<{
      [key: string]: HTMLDivElement | null
   }>({})
   const isManualScroll = useRef(false)
   const activeCategoryRef = useRef<HTMLButtonElement | null>(null)
   const categoryScrollContainerRef = useRef<HTMLDivElement | null>(null)

   const toggleSubcategory = (subcategoryKey: string) => {
      setExpandedSubcategories(prev => {
         const newSet = new Set(prev)
         if (newSet.has(subcategoryKey)) {
            newSet.delete(subcategoryKey)
         } else {
            newSet.add(subcategoryKey)
            // Scroll to the clicked subcategory
            const element = subcategorySectionRefs.current[t(subcategoryKey)]
            if (element) {
               const stickyHeaderHeight = window.innerWidth <= 768 ? 70 : 100
               const elementPosition =
                  element.getBoundingClientRect().top + window.pageYOffset
               window.scrollTo({
                  top: elementPosition - stickyHeaderHeight,
                  behavior: 'smooth',
               })
            }
         }
         return newSet
      })
   }

   const scrollToCategory = (
      category: string,
      subcategory: string | null = null
   ) => {
      isManualScroll.current = true
      setActiveCategory(category)
      setActiveSubcategory(subcategory)

      // Scroll to the appropriate section
      const element = subcategory
         ? subcategorySectionRefs.current[subcategory]
         : sectionRefs.current[category]

      if (element) {
         const offset = window.innerWidth <= 768 ? 70 : 100
         const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset
         window.scrollTo({
            top: elementPosition - offset,
            behavior: 'smooth',
         })
      }

      // Yatay scroll - kategori butonlarına
      if (activeCategoryRef.current && categoryScrollContainerRef.current) {
         centerSelectedCategory()
      }

      setTimeout(() => {
         isManualScroll.current = false
      }, 1000)
   }

   // Seçili kategoriyi ortala
   const centerSelectedCategory = () => {
      if (!activeCategoryRef.current || !categoryScrollContainerRef.current)
         return

      const container = categoryScrollContainerRef.current
      const button = activeCategoryRef.current
      const containerWidth = container.offsetWidth
      const buttonWidth = button.offsetWidth
      const buttonLeft = button.offsetLeft
      const containerScrollWidth = container.scrollWidth

      // İlk ve son kategori kontrolü
      const allButtons = container.querySelectorAll('.category-button')
      const firstButton = allButtons[0]
      const lastButton = allButtons[allButtons.length - 1]

      let scrollLeft = 0

      if (button === firstButton) {
         // İlk kategori için en başa scroll
         scrollLeft = 0
      } else if (button === lastButton) {
         // Son kategori için en sona scroll
         scrollLeft = containerScrollWidth - containerWidth
      } else {
         // Diğer kategoriler için ortala
         scrollLeft = buttonLeft - (containerWidth - buttonWidth) / 2
      }

      container.scrollTo({
         left: Math.max(
            0,
            Math.min(scrollLeft, containerScrollWidth - containerWidth)
         ),
         behavior: 'smooth',
      })
   }

   useEffect(() => {
      const handleScroll = () => {
         if (isManualScroll.current) return

         let closestSection = ''
         let closestSubsection = null
         let minDistance = Infinity

         // Check main categories
         Object.entries(menuData).forEach(([category, data]) => {
            const element = sectionRefs.current[t(data.nameKey)]
            if (element) {
               const rect = element.getBoundingClientRect()
               const distance = Math.abs(rect.top - 100)

               if (distance < minDistance) {
                  minDistance = distance
                  closestSection = t(data.nameKey)
                  closestSubsection = null
               }
            }

            // Check subcategories if they exist
            if (data.subcategories) {
               data.subcategories.forEach(subcategory => {
                  const element =
                     subcategorySectionRefs.current[t(subcategory.nameKey)]
                  if (element) {
                     const rect = element.getBoundingClientRect()
                     const distance = Math.abs(rect.top - 100)

                     if (distance < minDistance) {
                        minDistance = distance
                        closestSection = t(data.nameKey)
                        closestSubsection = t(subcategory.nameKey)
                     }
                  }
               })
            }
         })

         if (
            closestSection &&
            (closestSection !== activeCategory ||
               closestSubsection !== activeSubcategory)
         ) {
            setActiveCategory(closestSection)
            setActiveSubcategory(closestSubsection)
            centerSelectedCategory()
         }
      }

      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
   }, [activeCategory, activeSubcategory, t])

   // Sayfa yüklendiğinde ve ekran boyutu değiştiğinde seçili kategoriyi ortala
   useEffect(() => {
      centerSelectedCategory()
      window.addEventListener('resize', centerSelectedCategory)
      return () => window.removeEventListener('resize', centerSelectedCategory)
   }, [activeCategory])

   const renderMenuItems = (items: MenuItem[]) => {
      return (
         <div className="menu-item-container">
            {items
               .filter(item => item.price !== '' && item.price !== undefined)
               .map((item: MenuItem, index: number) => (
                  <motion.div
                     key={index}
                     className="menu-item"
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     exit={{ opacity: 0 }}
                     transition={{ duration: 0.3, delay: index * 0.1 }}>
                     <div className="menu-item-details">
                        <h3 className="menu-item-name">{t(item.nameKey)}</h3>
                        <p className="menu-item-description">
                           {t(item.descriptionKey)}
                        </p>
                        {item.flavorKey && (
                           <p className="menu-item-flavor">
                              {t(item.flavorKey)}
                           </p>
                        )}
                     </div>
                     <span className="menu-item-price">
                        {item.maxPrice
                           ? `${String(item.price)} - ${String(item.maxPrice)}`
                           : String(item.price)}
                     </span>
                  </motion.div>
               ))}
         </div>
      )
   }

   return (
      <div className="menu-container">
         <div className="sticky-category-bar-wrapper">
            <div
               className="category-scroll-container"
               ref={categoryScrollContainerRef}>
               {Object.entries(menuData).map(([category, data]) => (
                  <button
                     key={category}
                     ref={
                        activeCategory === t(data.nameKey)
                           ? activeCategoryRef
                           : null
                     }
                     className={`category-button ${
                        activeCategory === t(data.nameKey) ? 'active' : ''
                     }`}
                     onClick={() => scrollToCategory(t(data.nameKey))}>
                     {t(data.nameKey)}
                  </button>
               ))}
            </div>
         </div>

         <div className="menu-content">
            {Object.entries(menuData).map(([category, data]) => (
               <div
                  key={category}
                  ref={el => (sectionRefs.current[t(data.nameKey)] = el)}
                  className="menu-section">
                  <h2 className="menu-category-title">{t(data.nameKey)}</h2>
                  {data.subcategories
                     ? data.subcategories.map(
                          (subcategory: MenuSubcategory) => (
                             <div
                                key={subcategory.nameKey}
                                ref={el =>
                                   (subcategorySectionRefs.current[
                                      t(subcategory.nameKey)
                                   ] = el)
                                }
                                className="menu-subcategory-section">
                                <button
                                   className={`menu-subcategory-title ${
                                      expandedSubcategories.has(
                                         String(subcategory.nameKey)
                                      )
                                         ? 'expanded'
                                         : ''
                                   }`}
                                   onClick={() =>
                                      toggleSubcategory(
                                         String(subcategory.nameKey)
                                      )
                                   }>
                                   {t(subcategory.nameKey)}
                                   <motion.span
                                      className="dropdown-arrow"
                                      initial={false}
                                      animate={{
                                         rotate: expandedSubcategories.has(
                                            String(subcategory.nameKey)
                                         )
                                            ? 180
                                            : 0,
                                      }}
                                      transition={{
                                         duration: 0.3,
                                         ease: 'easeInOut',
                                      }}>
                                      ▼
                                   </motion.span>
                                </button>
                                <AnimatePresence>
                                   {expandedSubcategories.has(
                                      String(subcategory.nameKey)
                                   ) && (
                                      <motion.div
                                         initial={{
                                            height: 0,
                                            opacity: 0,
                                         }}
                                         animate={{
                                            height: 'auto',
                                            opacity: 1,
                                            transition: {
                                               height: {
                                                  duration: 0.4,
                                                  ease: [
                                                     0.04, 0.62, 0.23, 0.98,
                                                  ],
                                               },
                                               opacity: {
                                                  duration: 0.25,
                                                  delay: 0.15,
                                               },
                                            },
                                         }}
                                         exit={{
                                            height: 0,
                                            opacity: 0,
                                            transition: {
                                               height: {
                                                  duration: 0.3,
                                                  ease: [
                                                     0.04, 0.62, 0.23, 0.98,
                                                  ],
                                               },
                                               opacity: {
                                                  duration: 0.2,
                                               },
                                            },
                                         }}
                                         className="subcategory-items">
                                         <motion.div
                                            initial={{ y: 20 }}
                                            animate={{ y: 0 }}
                                            exit={{ y: -20 }}
                                            transition={{
                                               duration: 0.4,
                                               ease: [0.04, 0.62, 0.23, 0.98],
                                            }}>
                                            {renderMenuItems(subcategory.items)}
                                         </motion.div>
                                      </motion.div>
                                   )}
                                </AnimatePresence>
                             </div>
                          )
                       )
                     : data.items && renderMenuItems(data.items)}
               </div>
            ))}
         </div>
      </div>
   )
}

export default Menu
