import { FC } from 'react'
import styled from 'styled-components'
import Logo from '../assets/bobofranco.png'
import { Link } from 'react-router-dom'

const Footer: FC = () => {
   return (
      <footer className="bg-[#EEE3D1] py-8 mt-auto">
         <div className="container mx-auto py-20 text-center md:text-left md:flex animate animate-fade-in">
            <Link to="/" className="w-32 h-20">
               <img src={Logo} alt="Logo" className="h-full object-contain" />
            </Link>

            <div className="uppercase text-sm leading-7 font-normal text-black tracking-widest md:ml-32 xl:ml-48 xl:flex flex-wrap justify-center">
               <div className="mt-10 mb-8 md:mt-0 xl:mb-0 xl:mr-44">
                  <div>
                     Cemalpaşa Mah.
                     <br />
                     Bahar Cd. No: 17
                  </div>
                  <div>SEYHAN / ADANA</div>
               </div>

               <div>
                  SALI - PAZAR
                  <br />
                  10:00-00:00
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer
