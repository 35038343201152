import { FC } from 'react'
import Layout from './layout'
import Home from './pages/Home'
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom'
import { GlobalProvider } from './GlobalContext'
import AboutUs from './pages/AboutUs/AboutUs'
import GalleryScreen from './pages/Gallery/Gallery'

const router = createBrowserRouter([
   {
      element: <Layout />,
      children: [
         {
            path: '/',
            element: <Home />,
         },
         {
            path: '/about-us',
            element: <AboutUs />,
         },
         {
            path: '/gallery',
            element: <GalleryScreen />,
         },
         {
            path: '*',
            loader: () => redirect('/'), // Yanlış URL'lerde Home sayfasına yönlendir
         },
      ],
   },
])

const App: FC = () => (
   <GlobalProvider>
      <RouterProvider router={router} />
   </GlobalProvider>
)
export default App
